import { Container } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, Routes } from 'react-router-dom';

import Folio from './components/Folio/Folio';
import Skillset from './components/Skillset/Skillset';
import FolioDetail from './components/Folio/FolioDetail';
import Header from './components/Header/Header';
import { AppContext } from './context/ContextWrapper';
import { useContext } from 'react';

function App() {
    const context = useContext(AppContext);

    // ------------------------------------------------------------------------------------

    // THEME SETUP

    const bluntpixelTheme = createTheme({
        typography: {
            fontFamily: ['Helvetica Neue', 'Arial'].join(','),
            fontSize: 13,
            fontWeight: 200,
            h1: { fontWeight: 100 },
            h2: { fontWeight: 300 },
            h3: { fontWeight: 300 },
            h4: { fontWeight: 300 },
            h5: { fontWeight: 400 },
            h6: { fontWeight: 400 },
            body1: { fontWeight: 400 },
            body2: { fontWeight: 500 },
        },
        palette: {
            mode: context.darkMode ? 'dark' : 'light',
            type: context.darkMode ? 'dark' : 'light',
            primary: {
                main: context.darkMode ? '#333' : '#ceff2b',
            },
            secondary: {
                main: context.darkMode ? '#ceff2b' : '#ff9e00',
            },
            background: {
                default: context.darkMode ? '#333' : '#efefef',
                paper: context.darkMode ? '#343434' : '#fefefe',
            },
            text: {
                primary: context.darkMode ? '#ddd' : '#444',
            },
        },
        transitions: {
            duration: {
                enteringScreen: 1400,
                leavingScreen: 300,
            },
        },
    });

    // ------------------------------------------------------------------------------------

    // ROUTES

    const routes = [
        { path: '/', name: 'Home', icon: '', menu: 'main', show: false, Component: Folio },
        { path: '/folio_item/:id', name: 'Detail', icon: '', menu: 'main', show: true, Component: FolioDetail },
        { path: '/skillset', name: 'Skillset', icon: '', menu: 'main', show: true, Component: Skillset },
    ];

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <ThemeProvider theme={bluntpixelTheme}>
            <CssBaseline />
            <div className={context.darkMode ? 'dark' : 'light'}>
                <Header />
                <Container sx={{ mt: 12, mb: 12 }}>
                    <Routes>
                        {routes.map(({ name, path, Component }) => (
                            <Route key={name} path={path} element={<Component />}></Route>
                        ))}
                    </Routes>
                </Container>
            </div>
        </ThemeProvider>
    );
}

export default App;
