import React, { useState, useEffect } from 'react';
import { Typography, Grid, Tooltip, Zoom, Card, CardMedia, CardContent, Collapse } from '@mui/material';

function Skillset() {
    // ------------------------------------------------------------------------------------

    // LOAD  TAGS

    const [tagList, setTagList] = useState([]);
    const [rocking, setRocking] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);

    const correct = new Audio('/audio/pretender.mp3');
    const incorrect = new Audio('/audio/incorrect.mp3');

    useEffect(() => {
        async function loadTags() {
            const response = await fetch('https://bluntpixel.co.uk/content/wp-json/wp/v2/tags?per_page=99');
            if (!response.ok) {
                console.log('Nah mate');
                return;
            }

            const tags = await response.json();
            //tags.sort(() => Math.random() - 0.5);
            setTagList(tags);
            setPageLoaded(true);
        }
        document.title = `Bluntpixel - Skillset`;
        loadTags();
    }, []);

    // ------------------------------------------------------------------------------------

    // CLICKED ICON

    const handleIconClick = (e) => {
        const tech = e.target.dataset.tech;
        if (tech === 'Foo') {
            setRocking(true);
            correct.play();
            setTimeout(() => {
                setRocking(false);
            }, 5000);
        } else {
            incorrect.play();
        }
    };

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <>
            <Collapse in={pageLoaded} unmountOnExit>
                <Card elevation={6} style={{ mmarginTop: '50px' }} className={`${rocking ? 'rockin' : ''}`}>
                    <CardMedia
                        sx={{ height: 380, backgroundAttachment: /iPad|iPhone|iPod/.test(navigator.userAgent) ? '' : 'fixed', backgroundSize: /iPad|iPhone|iPod/.test(navigator.userAgent) ? 'cover' : 'contain', backgroundPosition: 'top' }}
                        image="/images/bilbo2.jpg"
                        loading="lazy"
                        alt="Biblo Baggins"
                    />
                    <CardContent className="detail_card">
                        <Typography gutterBottom variant="h5" style={{ textIndent: '-7px' }}>
                            "I don't know half of you half as well as I should like, and I like less than half of you half as well as you deserve."
                        </Typography>
                        <Typography gutterBottom variant="p">
                            <p>
                                All the <span className="strikethrough">bullshit</span> different technologies and tools I've had to deal with over the last 30 years. Bonus points if you can find the one that isn't real. Click on it to find out.
                            </p>
                        </Typography>
                        {pageLoaded && (
                            <Grid container spacing={2} style={{ marginTop: '20px', justifyContent: 'space-evenly' }}>
                                {tagList.map((tag, index) => (
                                    <Zoom key={index} in={pageLoaded} style={{ transitionDelay: pageLoaded ? `${(index + 1) * 40}ms` : '0ms' }}>
                                        <Grid key={index}>
                                            <div className="tech_icon_large" key={index}>
                                                <Tooltip title={tag.name} placement="top" arrow TransitionComponent={Zoom}>
                                                    <img src={`./images/tech_icons/${tag.name.toLowerCase()}.svg`} alt={tag.name} data-tech={tag.name} onClick={handleIconClick} />
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                    </Zoom>
                                ))}
                            </Grid>
                        )}
                    </CardContent>
                </Card>
            </Collapse>
        </>
    );
}

export default Skillset;
