import React, { useState, useEffect, useContext } from 'react';
import { Typography, Card, CardActionArea, CardContent, CardMedia, Grid, Tooltip, TablePagination, Backdrop, CircularProgress, Zoom, Slide } from '@mui/material';
import { parseDiviMarkup } from '../../utilities/diviParser';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/ContextWrapper';

function Folio() {
    // ------------------------------------------------------------------------------------

    // VARIABLES

    const context = useContext(AppContext);
    const [pageLoaded, setPageLoaded] = useState(false);

    // ------------------------------------------------------------------------------------

    // PAGINATION

    const handleChangePage = (e, value) => {
        context.setPage(parseInt(value));
    };

    const handleChangeRowsPerPage = (e, value) => {
        context.setRowsPerPage(parseInt(value.key.replace('.$', '')));
    };

    // ------------------------------------------------------------------------------------

    // ITEM SELECTED

    const handleItemClick = (index) => {};

    // ------------------------------------------------------------------------------------

    // LOAD FOLIO DATA

    useEffect(() => {
        async function loadPosts() {
            setPageLoaded(false);
            const response = await fetch(`https://bluntpixel.co.uk/content/wp-json/wp/v2/posts?_embed&per_page=${context.rowsPerPage}&page=${context.page + 1}&categories=88`);
            if (!response.ok) {
                console.log('Nah mate');
                return;
            }
            const posts = await response.json();
            context.setTotalPosts(parseInt(response.headers.get('x-wp-total')));

            const parsedData = [];

            for (let i = 0; i < posts.length; i++) {
                const newNode = {};

                newNode.id = posts[i].id;
                newNode.date_gmt = posts[i].date_gmt;
                if (posts[i]['_embedded']['wp:featuredmedia']) {
                    newNode.heroImage = posts[i]['_embedded']['wp:featuredmedia'][0].source_url;
                }
                newNode.title = posts[i].title.rendered;
                newNode.slug = posts[i].slug;
                newNode.content = parseDiviMarkup(posts[i].content.rendered);

                const tagData = posts[i]['_embedded']['wp:term'][1];
                let tagsArray = [];
                for (let t = 0; t < tagData.length; t++) tagsArray.push(tagData[t].name);
                newNode.tagsArray = [...tagsArray];

                parsedData.push(newNode);
            }
            context.setWpPosts(parsedData);
            setPageLoaded(true);
        }
        console.log('context.redrawList: ' + context.redrawList);
        if (!context.redrawList) {
            if (context.wpPosts.length === 0) {
                loadPosts();
            } else {
                context.setRedrawList(true);
                setPageLoaded(true);
            }
        } else {
            if (context.page !== -1) {
                loadPosts();
            }
        }

        document.title = 'Bluntpixel - Folio';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.page, context.rowsPerPage]);

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <>
            <div className={`gridview ${pageLoaded ? '' : 'loading'}`}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                        <Typography gutterBottom variant="h4" style={{ marginBottom: '30px' }}>
                            Latest things
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TablePagination
                            component="div"
                            labelRowsPerPage={'Per page:'}
                            rowsPerPageOptions={[10, 20, 40]}
                            count={context.totalPosts}
                            page={context.rowsPerPage >= context.totalPosts ? 0 : context.page}
                            onPageChange={handleChangePage}
                            rowsPerPage={context.rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    {context.wpPosts.map((item, index) => (
                        <Slide key={index} in={pageLoaded} mountOnEnter={true} unmountOnExit={false} direction="up" {...(pageLoaded ? { timeout: (index + 1) * 300 } : {})}>
                            <Grid item xs={12} md={6} onClick={() => handleItemClick(index)}>
                                <Card elevation={1}>
                                    <CardActionArea component={Link} to={`/folio_item/${item.id}`}>
                                        <CardMedia component="img" height="340" image={item.heroImage} loading="lazy" alt={item.heroImage} />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div" dangerouslySetInnerHTML={{ __html: item.title }}></Typography>
                                            <Typography variant="body1" color="text.secondary"></Typography>
                                            <div>
                                                {item.tagsArray.map((icon, iconIndex) => (
                                                    <Tooltip key={icon} title={icon} placement="top" arrow TransitionComponent={Zoom}>
                                                        <img className="tech_icon" src={`./images/tech_icons/${icon.toLowerCase()}.svg`} alt={icon} style={{ marginRight: '6px', marginTop: '20px' }} />
                                                    </Tooltip>
                                                ))}
                                            </div>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Slide>
                    ))}
                </Grid>

                {pageLoaded && (
                    <TablePagination
                        component="div"
                        labelRowsPerPage={'Per page:'}
                        rowsPerPageOptions={[10, 20, 40]}
                        count={context.totalPosts}
                        page={context.rowsPerPage >= context.totalPosts ? 0 : context.page}
                        onPageChange={handleChangePage}
                        rowsPerPage={context.rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}
            </div>

            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={!pageLoaded}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}
export default Folio;
