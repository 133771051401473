import React, { useState, useEffect, useContext } from 'react';
import { Typography, Card, CardContent, CardMedia, Tooltip, IconButton, Zoom, Collapse } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CloseIcon from '@mui/icons-material/Close';
import { parseDiviMarkup } from '../../utilities/diviParser';
import { useParams, Link } from 'react-router-dom';
import { AppContext } from '../../context/ContextWrapper';

function FolioDetail() {
    // ------------------------------------------------------------------------------------

    // VARIABLES

    const context = useContext(AppContext);
    const [detail, setDetail] = useState({});
    const [pageLoaded, setPageLoaded] = useState(false);
    const [pagination, setPagination] = useState({});
    let { id } = useParams();

    // ------------------------------------------------------------------------------------

    // LOAD FOLIO DATA

    useEffect(() => {
        context.setRedrawList(false);

        async function loadPost() {
            //console.log('id: ' + id);
            setPageLoaded(false);
            const response = await fetch(`https://bluntpixel.co.uk/content/wp-json/wp/v2/posts/${id}?categories=88&_embed`);

            if (!response.ok) {
                console.log('Nah mate');
                return;
            }
            const post = await response.json();
            //console.log(post);

            const newNode = {};
            newNode.id = post.id;
            newNode.date_gmt = post.date_gmt;
            if (post['_embedded']['wp:featuredmedia']) {
                newNode.heroImage = post['_embedded']['wp:featuredmedia'][0].source_url;
            }
            newNode.title = post.title.rendered;
            newNode.content = parseDiviMarkup(post.content.rendered);

            const tagData = post['_embedded']['wp:term'][1];
            let tagsArray = [];
            for (let t = 0; t < tagData.length; t++) tagsArray.push(tagData[t].name);
            newNode.tagsArray = [...tagsArray];

            setDetail(newNode);
            setPageLoaded(true);
            setPagination({
                previous: post.previous ? post.previous.id : null,
                current: parseInt(id),
                next: post.next ? post.next.id : null,
            });
            //console.log('pagingation: ', pagination);
            document.title = `Bluntpixel - ${newNode.title.replace('&#038;', '&')}`;
        }
        loadPost();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, pagination.current]);

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <>
            <div className="nav_buttons">
                <IconButton aria-label="Close" component={Link} to={`/`}>
                    <CloseIcon />
                </IconButton>
                <IconButton aria-label="Previous" disabled={!pagination.previous} component={Link} to={`/folio_item/${pagination.previous}`}>
                    <NavigateBeforeIcon />
                </IconButton>
                <IconButton aria-label="Next" disabled={!pagination.next} component={Link} to={`/folio_item/${pagination.next}`}>
                    <NavigateNextIcon />
                </IconButton>
            </div>

            <Collapse in={pageLoaded} unmountOnExit>
                <Card elevation={1} style={{ marginTop: '20px' }}>
                    <CardMedia
                        sx={{ height: 380, backgroundAttachment: /iPad|iPhone|iPod/.test(navigator.userAgent) ? '' : 'fixed', backgroundSize: /iPad|iPhone|iPod/.test(navigator.userAgent) ? 'cover' : 'contain', backgroundPosition: 'top' }}
                        image={detail.heroImage}
                        loading="lazy"
                        alt={detail.heroImage}
                    />

                    <CardContent className="detail_card">
                        <Typography className="indent" gutterBottom variant="h3" component="div" color="text.primary" dangerouslySetInnerHTML={{ __html: detail.title }}></Typography>
                        <Typography variant="body1" color="text.secondary">
                            {new Date(detail.date_gmt).toDateString()}
                        </Typography>
                        <Typography>
                            {detail.tagsArray?.map((icon, iconIndex) => (
                                <Tooltip key={icon} title={icon} placement="top" arrow TransitionComponent={Zoom}>
                                    <img className="tech_icon" src={`/images/tech_icons/${icon.toLowerCase()}.svg`} alt={icon} style={{ marginRight: '6px', marginTop: '20px' }} />
                                </Tooltip>
                            ))}
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: detail.content }}></div>
                    </CardContent>
                </Card>
            </Collapse>
        </>
    );
}
export default FolioDetail;
