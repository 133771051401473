import React, { useState, useEffect, createContext } from 'react';
import { getLocalStore, setLocalStore } from '../utilities/localStore';

export const AppContext = createContext(null);

export const ContextWrapper = (props) => {
    // ------------------------------------------------------------------------------------

    // STATE, REFS ETC

    const [totalPosts, setTotalPosts] = useState(0);
    const [page, setPage] = useState(-1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [wpPosts, setWpPosts] = useState([]);
    const [redrawList, setRedrawList] = useState(true);
    const [darkMode, setDarkMode] = useState(true);

    // ------------------------------------------------------------------------------------

    // INIT GET & SET LOCAL STORE ITEMS (SET DEFAULTS IF NOT ALEADY SET)

    useEffect(() => {
        const localStore = getLocalStore();
        setPage(typeof localStore.page === 'undefined' ? 0 : localStore.page);
        setRowsPerPage(typeof localStore.rowsPerPage === 'undefined' ? 10 : localStore.rowsPerPage);
        setDarkMode(typeof localStore.darkMode === 'undefined' ? true : localStore.darkMode);
    }, []);

    // ------------------------------------------------------------------------------------

    // STORE ITEMS ON CHANGE

    useEffect(() => {
        setLocalStore('page', page);
    }, [page]);

    useEffect(() => {
        setLocalStore('rowsPerPage', rowsPerPage);
    }, [rowsPerPage]);

    useEffect(() => {
        setLocalStore('darkMode', darkMode);
    }, [darkMode]);

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <AppContext.Provider
            value={{
                totalPosts,
                setTotalPosts,
                page,
                setPage,
                rowsPerPage,
                setRowsPerPage,
                wpPosts,
                setWpPosts,
                redrawList,
                setRedrawList,
                darkMode,
                setDarkMode,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};
