const setLocalStore = (key, value) => {
    const store = JSON.parse(localStorage.getItem('bluntpixel'));
    store[key] = value;
    localStorage.setItem('bluntpixel', JSON.stringify(store));
    return store;
};

const getLocalStore = () => {
    const store = JSON.parse(localStorage.getItem('bluntpixel'));
    if (!store) localStorage.setItem('bluntpixel', '{}');
    return JSON.parse(localStorage.getItem('bluntpixel'));
};

export { setLocalStore, getLocalStore };
