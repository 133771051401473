import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ContextWrapper } from './context/ContextWrapper';
import ReactGA from 'react-ga4';

import './index.scss';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

ReactGA.initialize('G-ZLDD5242DS');

root.render(
    //<React.StrictMode>
    <ContextWrapper>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ContextWrapper>,
    // </React.StrictMode>
);
