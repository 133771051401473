const parseDiviMarkup = (input) => {
    //
    if (input.includes('et_pb')) {
        const output = input

            .replace(/\[et_pb_image/g, '<img class="inline_image" ')
            .replace(/\[\/et_pb_image]/g, '/>')

            .replace(/\[et_pb_button.*?button_url=&#8221;/g, '<a class="button" target="_blank" href="')
            .replace(/&#8221; url_new_window.*?button_text=&#8221;/g, '" >')
            .replace(/&#8221; button_.*?\[\/et_pb_button]/g, '</a>')

            .replace(/\[et_pb.*?\]/g, '')
            .replace(/\[\/et_pb.*?\]/g, '')

            .replace(/&#8221;/g, '"')
            .replace(/&#8221;]/g, '"')

            .replace(/_builder_version.*?8243;]/g, '')

            .replace(/src="..\/..\/projects/g, 'src="https://bluntpixel.co.uk/projects/')
            .replace(/img class="/g, 'img class="inline_image ');

        return output;
    } else {
        return input;
    }
};

export { parseDiviMarkup };
