import { Typography, Toolbar, AppBar, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/ContextWrapper';
import { useContext } from 'react';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

function Header() {
    // ------------------------------------------------------------------------------------

    // VARS

    const context = useContext(AppContext);

    // ------------------------------------------------------------------------------------

    // DARKMODE SWITCH

    const handleModeChange = (event) => {
        context.setDarkMode(!context.darkMode);
    };

    // ------------------------------------------------------------------------------------

    // RENDER

    return (
        <AppBar color="primary" elevation={1} sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '0px 10px 0px 0px' }}>
            <Toolbar>
                <img src="/images/bp.png" alt="Bluntpixel Logo" height="30px" style={{ marginRight: '10px' }} />
                <Link to={'/'} relative="path">
                    <Typography variant="h6" underline="none" style={{ marginRight: '20px' }}>
                        bluntpixel
                    </Typography>
                </Link>
                <Link to={'/'} relative="path">
                    <Typography variant="body1" color="grey" underline="none" style={{ margin: '10px' }}>
                        Folio
                    </Typography>
                </Link>
                <Link to={'/skillset'} relative="path">
                    <Typography variant="body1" color="grey" underline="none" style={{ margin: '10px' }}>
                        Skillset
                    </Typography>
                </Link>
            </Toolbar>

            <IconButton sx={{ ml: 1 }} onClick={handleModeChange} color="inherit">
                {context.darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
        </AppBar>
    );
}

export default Header;
